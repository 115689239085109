import React, { useContext, useEffect, useState } from "react";
import { Document, Page, Text, View, StyleSheet, PDFViewer, Image } from "@react-pdf/renderer";
import bgImage from "assets/img/bg-AWB.png";
import AxiosContext from '../../store/axios-context';
import AuthContext from '../../store/auth-context';
import { useHistory } from 'react-router';
import { useLocation } from "react-router-dom";
import { useParams } from "react-router-dom";

const styles = StyleSheet.create({
  viewer: {
    width: "100%",
    height: "calc(100vh - 94px)",

  },
  page: {
    fontFamily: 'Helvetica',
    fontSize: 8,
    paddingTop: 10,
    paddingLeft: 10,
    paddingRight: 10,
    flexDirection: 'column',
    position: 'relative',

  },
  bgImg: {
    top: 8,
    left: 5,
    right: 0,
    position: 'absolute'
  },
  header: {
    marginBottom: 20,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap'
  },
  col6: {
    width: '50%',
    marginBottom: 10,
    paddingLeft: 10,
    paddingRight: 10
  },
  col7: {
    width: '65%',
    marginBottom: 10,
    paddingLeft: 10,
    paddingRight: 10
  },
  col5: {
    width: '35%',
    marginBottom: 10,
    paddingLeft: 10,
    paddingRight: 10
  },

  bold: {
    fontWeight: '600',
    fontSize: 12,
  },
  dflex: {
    display: 'flex',
  },

  thcol: {
    borderWidth: 1,
    borderColor: '#999',
    borderBottomWidth: 1,
    padding: 5,
    flex: '1 1 auto',
    marginBottom: -1,
    marginRight: -1,
  },
  tdcol: {

    padding: 4,
    flex: '1 1 auto',
    marginBottom: -1,
    marginRight: -1,
  },
  description: {
    borderWidth: 1,
    borderColor: '#999',
    borderBottomWidth: 1,
    padding: 5,
    marginBottom: -1,
    marginRight: -1,
    width: '40%'
  },

  thHeading: {
    borderWidth: 1,
    borderColor: '#999',
    borderBottomWidth: 1,
    padding: 5,
    marginBottom: -1,
    marginRight: -1,
    width: '100%',
    textAlign: 'center',
    fontWeight: 'bold',
  },

  total: {
    borderWidth: 1,
    borderColor: '#fff',
    borderBottomWidth: 1,
    padding: 5,
    marginTop: 1,
    marginRight: -1,
    width: '50%',
    textAlign: 'right'
  },
  tdBorderless: {
    borderWidth: 1,
    borderColor: '#fff',
    borderBottomWidth: 1,
    padding: 5,
    flex: '1 1 auto',
    marginTop: 1,
    marginRight: -1,
  },

  marginY5: {
    marginTop: 25,
    marginBottom: 25
  },

  borderBottom: {


  },
  border: {
    borderWidth: 1,
    borderColor: '#999',
    paddingRight: 1,
    marginRight: -1,
    paddingBottom: 2
  },
  borderDashTop: {
    borderTopWidth: 1,
    borderColor: '#999',
    borderStyle: 'dashed'
  },

  withoutBorder: {
    marginLeft: 25,
    textAlign: 'center',
    width: 160,
    fontSize: 8,
  },
  padding10: {
    padding: 10,
  },
  w100: {
    width: '100%',
  },
  bold: {
    fontFamily: 'Helvetica-Bold',
  }


});

function AirwayBillPdf() {

  const location = useLocation();
  const history = useHistory();
  const axiosCtx = useContext(AxiosContext);
  const authCtx = useContext(AuthContext);

  const [shipper, setShipper] = useState([]);
  const [consignee, setConsignee] = useState([]);
  const [origin, setOrigin] = useState([]);
  const [destination, setDestination] = useState([]);
  const [piece, setPiece] = useState('');
  const [weight, setWeight] = useState('');
  const [volume, setVolume] = useState(0);
  const [volumetricWeight, setVolumetricWeight] = useState(0);
  const [totalPieces, setTotalPieces] = useState(0);
  const [ntrOfGoods, setNtrOfGoods] = useState('');
  const [pickupLoc, setPickupLoc] = useState('');
  const [shc, setShc] = useState('');
  const [hawbhcvbNo, setHawbhcvbNo] = useState('');
  const [mawbmcvbNo, setMawbmcvbNo] = useState('');
  const [prefix, setPrefix] = useState([]);

  const [originAirport, setOriginAirport] = useState('');
  const [finalDestination, setFinalDestination] = useState('');
  const [destTo1, setDestTo1] = useState('');
  const [destFlightNo1, setDestFlightNo1] = useState('');
  const [destFlightDate1, setDestFlightDate1] = useState('');
  const [onwdTo1, setOnwdTo1] = useState('');
  const [onwdFlightNo1, setOnwdFlightNo1] = useState('');
  const [onwdFlightDate1, setOnwdFlightDate1] = useState('');
  const [onwdTo2, setOnwdTo2] = useState('');
  const [onwdFlightNo2, setOnwdFlightNo2] = useState('');
  const [onwdFlightDate2, setOnwdFlightDate2] = useState('');

  const [handlingInformation, setHandlingInformation] = useState('');
  const [additionalInformation, setAdditionalInformation] = useState('');
  const [ssrInformation, setSsrInformation] = useState('');

  const [currency, setCurrency] = useState('');
  const [accCurrency, setAccCurrency] = useState('AED');
  const [decCustoms, setDecCustoms] = useState('NCV');
  const [decCarriage, setDecCarriage] = useState('NVD');
  const [currencies, setCurrencies] = useState([]);

  const [parties, setParties] = useState([]);
  const [ports, setPorts] = useState([]);


  const [accWeight, setAccWeight] = useState('');
  const [dueAgent, setDueAgent] = useState('');
  const [paymentMethod, setPaymentMethod] = useState('');
  const [dueCarrier, setDueCarrier] = useState('');
  const [modeOfPayment, setModeOfPayment] = useState('');
  const [productCode, setProductCode] = useState('');
  const [valuationCharge, setValuationCharge] = useState('');
  const [customsOrigin, setCustomsOrigin] = useState('');
  const [currencyConvertion, setCurrencyConvertion] = useState('');


  const [signDate, setSignDate] = useState('');
  const [signTime, setSignTime] = useState('');
  const [signPlace, setSignPlace] = useState('');
  const [customSerialNo, setCustomSerialNo] = useState('');
  const [signOfShipper, setSignOfShipper] = useState('');
  const [signOfCarrier, setSignOfCarrier] = useState('');

  const [totalDimsPieces, setTotalDimsPieces] = useState('');
  const [volumeDims, setVolumeDims] = useState('');
  const [volumetricWeightDims, setVolumetricWeightDims] = useState('');

  const [weightUnit, setWeightUnit] = useState('Kg');
  const [rateClass, setRateClass] = useState('Q');
  const [iataRate, setIataRate] = useState(0);
  const [totalIataRate, setTotalIataRate] = useState('');
  const [natureOfGoods, setNatureOfGoods] = useState(0);

  const [totalPrepaid, setTotalPrepaid] = useState(0);
  const [totalCollect, setTotalCollect] = useState(0);
  const [grandTotal, setGrandTotal] = useState(0);
  const [airwayBillId, setAirwayBillId] = useState(0);

  const [totalOtherChargeDueAgentPrepaid, setTotalOtherChargeDueAgentPrepaid] = useState(0);
  const [totalOtherChargeDueCarrierPrepaid, setTotalOtherChargeDueCarrierPrepaid] = useState(0);

  const [totalOtherChargeDueAgentCollect, setTotalOtherChargeDueAgentCollect] = useState(0);
  const [totalOtherChargeDueCarrierCollect, setTotalOtherChargeDueCarrierCollect] = useState(0);

  const [dimsFormValues, setDimsFormValues] = useState([{ piece: "", unit: "", length: "", width: "", height: "", volume: "", volumetric_weight: "" }])

  const [accFormValues, setAccFormValues] = useState([{ code: "", accounting: "" }]);
  const [otherChargePrinting, setOtherChargePrinting] = useState([{ charge_code: "", payment_mode: "", agent_airline: "", amount: "" }]);

  const { id } = useParams();

  useEffect(() => {
    if (authCtx.isLoggedIn == false) {
      history.replace('/auth/Login');
    }
    getAirwayBillById(id);
    // if (location.state && location.state.data != null) {

    // }
  }, []);

  useEffect(() => {
    if (authCtx.isLoggedIn == false) {
      history.replace('/auth/Login');
    }
    totalOtherChargeDueAgentPrepaidCal();
    totalOtherChargeDueCarrierPrepaidCal();
    totalOtherChargeDueAgentCollectCal();
    totalOtherChargeDueCarrierCollectCal();
  }, [otherChargePrinting]);

  const totalOtherChargeDueAgentPrepaidCal = () => {
    const totOthagent = otherChargePrinting.reduce((total, currentValue) => ((currentValue.agent_airline == "1") && (currentValue.payment_mode == "1")) ? total = parseInt(total) + parseInt(currentValue.amount) : total = total, 0);
    setTotalOtherChargeDueAgentPrepaid(totOthagent);
  }
  const totalOtherChargeDueCarrierPrepaidCal = () => {
    const totOthaCarr = otherChargePrinting.reduce((total, currentValue) => ((currentValue.agent_airline == "2") && (currentValue.payment_mode == "1")) ? total = parseInt(total) + parseInt(currentValue.amount) : total = total, 0);
    setTotalOtherChargeDueCarrierPrepaid(totOthaCarr);
  }
  const totalOtherChargeDueAgentCollectCal = () => {
    const totOthagent = otherChargePrinting.reduce((total, currentValue) => ((currentValue.agent_airline == "1") && (currentValue.payment_mode == "2")) ? total = parseInt(total) + parseInt(currentValue.amount) : total = total, 0);
    setTotalOtherChargeDueAgentCollect(totOthagent);
  }
  const totalOtherChargeDueCarrierCollectCal = () => {
    const totOthaCarr = otherChargePrinting.reduce((total, currentValue) => ((currentValue.agent_airline == "2") && (currentValue.payment_mode == "2")) ? total = parseInt(total) + parseInt(currentValue.amount) : total = total, 0);
    setTotalOtherChargeDueCarrierCollect(totOthaCarr);
  }

  const getAirwayBillById = (airwaybill_id) => {
    const airwaybillRequest = axiosCtx.instance.get(`airway-bill/${airwaybill_id}`);

    Promise.all([airwaybillRequest]).then(([airwaybillResponse]) => {

      if (airwaybillResponse.status === 200) {
        setShipper(airwaybillResponse.data.data.shipper ? airwaybillResponse.data.data.shipper : '');
        setOrigin(airwaybillResponse.data.data.origin ? airwaybillResponse.data.data.origin : '');
        setDestination(airwaybillResponse.data.data.destination ? airwaybillResponse.data.data.destination : '');
        setPiece(airwaybillResponse.data.data.piece);
        setWeight(airwaybillResponse.data.data.weight);
        setVolume(airwaybillResponse.data.data.volume);
        setNtrOfGoods(airwaybillResponse.data.data.ntr_of_goods);
        setPickupLoc(airwaybillResponse.data.data.pickup_loc);
        setShc(airwaybillResponse.data.data.shc);
        setHawbhcvbNo(airwaybillResponse.data.data.hawb_hcbv_no);
        setPrefix(airwaybillResponse.data.data.prefix);
        setMawbmcvbNo(airwaybillResponse.data.data.mawb_mcbv_no);
        setConsignee(airwaybillResponse.data.data.consignee ? airwaybillResponse.data.data.consignee : '');
        setHandlingInformation(airwaybillResponse.data.data.handling_information);
        setAdditionalInformation(airwaybillResponse.data.data.additional_information);
        setSsrInformation(airwaybillResponse.data.data.ssr_information);
        setCurrency(airwaybillResponse.data.data.currency ? airwaybillResponse.data.data.currency : '');
        setDecCustoms(airwaybillResponse.data.data.dec_customs);
        setDecCarriage(airwaybillResponse.data.data.dec_carriage);

        setOriginAirport(airwaybillResponse.data.data.routing_details.origin_airport);
        setFinalDestination(airwaybillResponse.data.data.routing_details.final_destination);
        setDestTo1(airwaybillResponse.data.data.routing_details.dest_to_1);
        setDestFlightNo1(airwaybillResponse.data.data.routing_details.dest_flight_number_1);
        setDestFlightDate1(airwaybillResponse.data.data.routing_details.dest_flight_date_1);
        setOnwdTo1(airwaybillResponse.data.data.routing_details.onwd_to_1);
        setOnwdFlightNo1(airwaybillResponse.data.data.routing_details.onwd_flight_number_1);
        setOnwdFlightDate1(airwaybillResponse.data.data.routing_details.onwd_flight_date_1);
        setOnwdTo2(airwaybillResponse.data.data.routing_details.onwd_to_2);
        setOnwdFlightNo2(airwaybillResponse.data.data.routing_details.onwd_flight_number_2);
        setOnwdFlightDate2(airwaybillResponse.data.data.routing_details.onwd_flight_date_2);

        setAccCurrency(airwaybillResponse.data.data.accounting_information ? airwaybillResponse.data.data.accounting_information.currency_id : '');
        setDecCarriage(airwaybillResponse.data.data.accounting_information.dec_carriage);
        setDecCustoms(airwaybillResponse.data.data.accounting_information.dec_customs);
        setAccWeight(airwaybillResponse.data.data.accounting_information.weight);
        setDueAgent(airwaybillResponse.data.data.accounting_information.due_agent_dha);
        setPaymentMethod(airwaybillResponse.data.data.accounting_information.payment_method);
        setDueCarrier(airwaybillResponse.data.data.accounting_information.due_carrier);
        setModeOfPayment(airwaybillResponse.data.data.accounting_information.mode_of_payment);
        setProductCode(airwaybillResponse.data.data.accounting_information.product_code);
        setValuationCharge(airwaybillResponse.data.data.accounting_information.valuation_charge);
        setCustomsOrigin(airwaybillResponse.data.data.accounting_information.customs_origin);
        setCurrencyConvertion(airwaybillResponse.data.data.accounting_information.currency_conversion);

        setSignPlace(airwaybillResponse.data.data.place);
        setSignDate(airwaybillResponse.data.data.date);
        setSignTime(airwaybillResponse.data.data.time);
        setCustomSerialNo(airwaybillResponse.data.data.customs_serial_no);
        setSignOfShipper(airwaybillResponse.data.data.sign_of_shipper);
        setSignOfCarrier(airwaybillResponse.data.data.sign_of_carrier);

        setTotalPrepaid(airwaybillResponse.data.data.total_prepaid);
        setTotalCollect(airwaybillResponse.data.data.total_collect);
        setGrandTotal(airwaybillResponse.data.data.grand_total);

        setWeightUnit(airwaybillResponse.data.data.weight_unit);
        setRateClass(airwaybillResponse.data.data.rate_class);
        setIataRate(airwaybillResponse.data.data.iata_rate);
        setTotalIataRate(airwaybillResponse.data.data.total_iata_rate);
        setNatureOfGoods(airwaybillResponse.data.data.nature_of_goods);

        setOtherChargePrinting(JSON.parse(airwaybillResponse.data.data.other_charge_printing_fields));

        setTotalDimsPieces(airwaybillResponse.data.data.dims_total_piece);
        setVolumeDims(airwaybillResponse.data.data.dims_total_volume);
        setVolumetricWeightDims(airwaybillResponse.data.data.dims_total_volumetric_weight);
        setDimsFormValues(JSON.parse(airwaybillResponse.data.data.dims_fileds));

        setAccFormValues(JSON.parse(airwaybillResponse.data.data.accounting_codes));

      }

    }).catch((error) => {
      // TODO: Handle Error
      console.log(error)
      alert('Sorry, something went wrong!');
    });
  };
  return (
    <>
      <div className="content pdfViewer">
        <PDFViewer style={styles.viewer}>
          <Document>
            <Page size="A4" style={styles.page}>


              <View style={styles.bgImg} >
                <Image style={styles.bgImage} src={bgImage} />
              </View>
              <View style={styles.row} >
                <View style={[styles.tdcol, { borderTopColor: '#fff', borderLeftColor: '#fff', maxWidth: 30 }]}>
                  <Text>{prefix.prefix}</Text>
                </View>

                <View style={[styles.tdcol, { borderTopColor: '#fff', maxWidth: 50 }]}>
                  <Text>DXB</Text>
                </View>

                <View style={[styles.tdcol, { borderTopColor: '#fff', maxWidth: 100 }]}>
                  <Text>{mawbmcvbNo}</Text>
                </View>

                <View style={[styles.tdcol, { borderTopColor: '#fff', borderRightColor: '#fff', fontSize: 14, textAlign: 'center' }]}>
                  <Text>{prefix.prefix}-{mawbmcvbNo}</Text>
                </View>


              </View>

              <View style={styles.row} >
                <View style={[styles.tdcol, { padding: 0, minHeight: 75 }]}>
                  <View style={styles.row} >
                    <View style={[styles.tdcol, { borderBottomColor: '#fff', borderLeftColor: '#fff', borderTopColor: '#fff' }]}>
                      <Text style={{ marginBottom: 5 }}>   </Text>
                      <Text> {shipper.name}  </Text>
                      <Text>{shipper.address_1}  </Text>
                      <Text>{shipper.state}</Text>
                      <Text>{shipper.country}</Text>
                      <Text>{shipper.zip_code}</Text>

                    </View>

                    <Text style={[styles.tdcol, { minHeight: 25, }]}>

                    </Text>
                  </View>
                </View>
                <View style={[styles.tdcol]}>
                  <Text style={[{ paddingTop: 25, paddingLeft: 30 }]}>{prefix.carrier_name}</Text>
                  <Text style={[{ paddingTop: 1, paddingLeft: 30 }]}>{prefix.carrier_address}</Text>

                </View>
              </View>
              <View style={styles.row} >
                <View style={[styles.tdcol, { padding: 0, minHeight: 78 }]}>
                  <View style={styles.row} >
                    <View style={[styles.tdcol, { borderBottomColor: '#fff', borderLeftColor: '#fff', borderTopColor: '#fff' }]}>
                      <Text style={{ marginBottom: 5 }}>   </Text>
                      <Text> {consignee.name}</Text>
                      <Text> {consignee.address_1}</Text>
                      <Text> {consignee.state}</Text>
                      <Text> {consignee.country} </Text>
                      <Text>{consignee.zip_code}</Text>
                    </View>
                    <Text style={[styles.tdcol]}>
                    </Text>
                  </View>
                </View>
                <View style={[styles.tdcol]}>


                </View>
              </View>

              <View style={styles.row} >
                <View style={[styles.tdcol, { padding: 0, minHeight: 50 }]}>
                  <View style={{ paddingTop: 15, paddingLeft: 5 }}>

                    <Text> SEA TRUST SHIPPING SERVICES LLC</Text>
                    <Text> DUBAI AE,TL:048786950</Text>
                  </View>
                </View>
                <View style={[styles.tdcol, { paddingTop: 15 }]}>
                  {accFormValues && accFormValues.map((element, index) => (
                    <Text>
                      {element.code}
                    </Text>
                  ))}
                </View>
              </View>

              <View style={styles.row} >
                <View style={[styles.tdcol, { padding: 0 }]}>
                  <View style={[styles.row]} >
                    <View style={[styles.tdcol]}>


                      <Text style={[{ width: '100%', textAlign: "center", paddingTop: 15 }]}>      </Text>
                    </View>
                    <View style={[styles.tdcol]}>


                      <Text style={[{ width: '100%', paddingTop: 15 }]}>       </Text>
                    </View>

                  </View>
                </View>
                <View style={[styles.tdcol, { paddingTop: 18 }]}>
                  {/* <Text>MODE OF PAYMENT:Prepaid</Text>   */}

                </View>
              </View>
              {/* -------------------------------------------------- */}
              <View style={styles.row} >
                <View style={[styles.tdcol, { padding: 0, }]}>
                  <View style={{ paddingTop: 10, paddingLeft: 5 }}>

                    <Text> {originAirport}</Text>
                  </View>
                </View>

                <View style={[styles.tdcol, { padding: 0 }]}>
                  <View style={[styles.row]} >
                    <View style={[styles.tdcol, { padding: 0 }]}>

                      <Text style={[{ fontSize: 10, paddingTop: 8, paddingLeft: 5 }]}> </Text>
                    </View>
                    <View style={[styles.tdcol, { padding: 0, minWidth: 40 }]}>

                      <Text style={[{ width: '100%', textAlign: "center", marginTop: 2, fontSize: 10, minHeight: 20, paddingTop: 5 }]}>       </Text>
                    </View>

                    <View style={[styles.tdcol, { padding: 0 }]}>
                      <Text style={[{ paddingTop: 8, fontSize: 10, textAlign: "center" }]}> CMT </Text>
                    </View>
                  </View>
                </View>
              </View>
              {/* -------------------------------------------------- */}

              <View style={styles.row} >


                <View style={[styles.tdcol, { padding: 0, textAlign: "center", borderBottomColor: '#fff' }]}>

                  <View style={[styles.row]} >
                    {/* <Text style={[styles.tdcol, {paddingTop:15, paddingLeft:0, paddingRight:0,borderLeftColor: '#fff',  minWidth:10 }]}> {destTo1} {onwdTo1} {onwdTo2}  </Text> */}
                    <Text style={[styles.tdcol, { paddingTop: 15, paddingLeft: 0, paddingRight: 0, borderLeftColor: '#fff', minWidth: 10 }]}> {destTo1}  </Text>
                    <Text style={[styles.tdcol, { paddingTop: 15, paddingLeft: 0, paddingRight: 0, minWidth: 115 }]}>{prefix.carrier_code} </Text>

                    <Text style={[styles.tdcol, { paddingTop: 15, paddingLeft: 0, paddingRight: 0, }]}>   </Text>
                    <Text style={[styles.tdcol, { paddingTop: 15, paddingLeft: 0, paddingRight: 0, }]}>   </Text>
                    <Text style={[styles.tdcol, { paddingTop: 15, paddingLeft: 0, paddingRight: 0, }]}>   </Text>
                    <Text style={[styles.tdcol, { paddingTop: 15, paddingLeft: 0, paddingRight: 0, }]}>   </Text>
                  </View>
                </View>


                <View style={[styles.tdcol, { padding: 0, minWidth: 25, textAlign: "center", fontSize: 10, borderBottomColor: '#fff' }]}>
                  <View style={[styles.row]} >

                    <View style={[styles.tdcol, { paddingTop: 18, paddingLeft: 0, paddingRight: 0, paddingBottom: 0, borderTopColor: '#fff', maxWidth: 30 }]}>
                      <Text style={[{ fontSize: 10, textAlign: "center" }]}>{currency.currency_label}</Text>
                    </View>
                    <View style={[styles.tdcol, { paddingTop: 18, paddingLeft: 0, paddingRight: 0, paddingBottom: 0, borderTopColor: '#fff', maxWidth: 20 }]}>
                      <Text style={[{ fontSize: 10, textAlign: "center" }]}>PP</Text>
                    </View>
                    <View style={[styles.tdcol, { paddingTop: 10, paddingLeft: 0, paddingRight: 0, paddingBottom: 0, borderTopColor: '#fff', maxWidth: 60 }]}>

                      <View style={[styles.row]} >
                        <View style={[styles.tdcol, { paddingTop: 8, paddingLeft: 0, paddingRight: 0, borderBottomColor: '#fff', fontSize: 6, textAlign: "center", borderLeftColor: '#fff' }]} >
                          <Text style={[{ fontSize: 10, textAlign: "center" }]}>X</Text>
                        </View>
                        <View style={[styles.tdcol, { paddingTop: 8, paddingLeft: 0, paddingRight: 0, borderBottomColor: '#fff', fontSize: 6, textAlign: "center", borderLeftColor: '#fff' }]} >
                          <Text style={[{ fontSize: 10, textAlign: "center" }]}> </Text>
                        </View>

                        <View style={[styles.tdcol, { paddingTop: 8, paddingLeft: 0, paddingRight: 0, borderBottomColor: '#fff', fontSize: 6, textAlign: "center", borderLeftColor: '#fff' }]} >
                          <Text style={[{ fontSize: 10, textAlign: "center" }]}> </Text>
                        </View>
                        <View style={[styles.tdcol, { paddingTop: 8, paddingLeft: 0, paddingRight: 0, borderBottomColor: '#fff', fontSize: 6, textAlign: "center", borderLeftColor: '#fff' }]} >
                          <Text style={[{ fontSize: 10, textAlign: "center" }]}> </Text>
                        </View>
                      </View>
                    </View>

                    <View style={[styles.tdcol, { paddingTop: 15, paddingLeft: 0, paddingRight: 0, paddingBottom: 0, borderTopColor: '#fff', minWidth: 35 }]}>
                      <Text style={[{ fontSize: 10, textAlign: "center" }]}>{decCarriage}</Text>
                    </View>
                    <View style={[styles.tdcol, { paddingTop: 15, paddingLeft: 0, paddingRight: 0, paddingBottom: 0, borderTopColor: '#fff', minWidth: 35 }]}>
                      <Text style={[{ fontSize: 10, textAlign: "center" }]}>{decCustoms}</Text>
                    </View>

                  </View>
                </View>


              </View>
              {/* -------------------------------------------------- */}

              <View style={styles.row} >
                <View style={[styles.tdcol, { padding: 0 }]}>
                  <View style={[styles.row]} >
                    <View style={[styles.tdcol, { borderLeftColor: '#fff', borderTopColor: '#fff' }]}>
                      <Text style={[{ fontSize: 10, textAlign: "center", paddingTop: 9, }]}>{finalDestination}</Text>
                    </View>
                    <View style={[styles.tdcol, { padding: 0 }]}>

                      <View style={[styles.row]} >
                        <Text style={[styles.tdcol, { paddingTop: 15, paddingLeft: 0, paddingRight: 0, paddingBottom: 0, }]}> {destFlightNo1} /{destFlightDate1 ? destFlightDate1.slice(-2) : ''}  </Text>
                        <Text style={[styles.tdcol, { paddingTop: 15, paddingLeft: 0, paddingRight: 0, paddingBottom: 0, }]}>  {onwdFlightNo1}/{onwdFlightDate1 ? onwdFlightDate1.slice(-2) : ''}  </Text>
                      </View>
                    </View>
                  </View>
                </View>
                <View style={[styles.tdcol, { padding: 0 }]}>
                  <View style={[styles.row]} >
                    <View style={[styles.tdcol, { paddingTop: 15, paddingLeft: 0, paddingRight: 0, paddingBottom: 0, }]}>
                      <Text>  </Text>
                    </View>
                    <View style={[styles.tdcol, { paddingTop: 15, paddingLeft: 0, paddingRight: 0, paddingBottom: 0, }]}>

                    </View>
                  </View>

                </View>
              </View>
              {/* -------------------------------------------------- */}
              <View style={styles.row} >
                <View style={[styles.tdcol]}>
                  <Text style={{ marginTop: 5, paddingTop: 5, paddingLeft: 5, height: 20, }}>
                    {handlingInformation}
                  </Text>
                </View>

              </View>

              <View style={styles.row} >
                <View style={[styles.tdcol, { padding: 0, minWidth: 400, }]}>

                </View>
                <View style={[styles.tdcol, { textAlign: "center", padding: 10 }]}>

                </View>
              </View>

              {/* -------------------------------------------------- */}



              <View style={[styles.row, { paddingTop: 25, }]} >
                <View style={[styles.tdcol, { padding: 0, minWidth: 40 }]}>

                  <View style={styles.row} >
                    <View style={[styles.tdcol, {}]}>
                      <Text style={{ paddingLeft: 7 }}>
                        {piece}
                      </Text>
                    </View>
                    <View style={[styles.tdcol, {}]}>
                      <Text style={{ paddingLeft: 7 }}>
                        {weight}
                      </Text>
                    </View>
                  </View>

                </View>



                <View style={[styles.tdcol, { padding: 0, maxWidth: 15 }]}>
                  <Text style={[styles.borderBottom, { paddingTop: 5, paddingLeft: 2, fontSize: 7 }]}>
                    {weightUnit.charAt(0)}
                  </Text>
                </View>

                <View style={[styles.tdcol, { padding: 0, maxWidth: 10 }]}>
                  <Text style={[styles.borderBottom, { fontSize: 7, paddingLeft: 1, textAlign: "center", paddingTop: 5, }]}>
                    {rateClass}
                  </Text>
                </View>
                <View style={[styles.tdcol, { padding: 0, minWidth: 25 }]}>
                  <Text style={[styles.borderBottom, { padding: 5, }]}>

                  </Text>
                </View>
                <View style={[styles.tdcol, { padding: 0, minWidth: 25 }]}>
                  <Text style={[styles.borderBottom, { padding: 5, }]}>
                    {volumetricWeightDims}
                  </Text>
                </View>
                <View style={[styles.tdcol, { padding: 0, minWidth: 25 }]}>
                  <Text style={[styles.borderBottom, { padding: 5, }]}>
                    {iataRate}
                  </Text>
                </View>

                <View style={[styles.tdcol, { padding: 0, minWidth: 60 }]}>
                  <Text style={[styles.borderBottom, { padding: 5, }]}>
                    {(totalIataRate) ? Number(totalIataRate).toFixed(2) : ''}
                  </Text>
                </View>
                <View style={[styles.tdcol, { padding: 0, minWidth: 100, height: 160 }]}>
                  <Text style={[styles.borderBottom, { padding: 5, }]}>
                    {natureOfGoods}{"\n"}
                    DIMS. IN CM. {"\n"}
                    {dimsFormValues && dimsFormValues.map((element, index) => (
                      <>
                        {element.length}x{element.width}x{element.height}/{element.piece} {"\n"}
                      </>
                    ))}
                  </Text>
                </View>
              </View>



              <View style={[styles.row,]} >
                <View style={[styles.tdcol, { padding: 0, minWidth: 40 }]}>
                  <View style={styles.row} >
                    <View style={[styles.tdcol, {}]}>
                      <Text style={{ paddingLeft: 7 }}>
                        {piece}
                      </Text>
                    </View>
                    <View style={[styles.tdcol, {}]}>
                      <Text style={{ paddingLeft: 7 }}>
                        {weight}
                      </Text>
                    </View>
                  </View>
                </View>
                <View style={[styles.tdcol, { padding: 0, maxWidth: 15 }]}>
                </View>
                <View style={[styles.tdcol, { padding: 0, maxWidth: 10 }]}>
                </View>
                <View style={[styles.tdcol, { padding: 0, minWidth: 25 }]}>
                </View>
                <View style={[styles.tdcol, { padding: 0, minWidth: 25 }]}>
                </View>
                <View style={[styles.tdcol, { padding: 0, minWidth: 25 }]}>
                </View>
                <View style={[styles.tdcol, { padding: 0, minWidth: 60 }]}>
                  <Text style={[styles.borderBottom, { padding: 5, }]}>
                    {(totalIataRate) ? Number(totalIataRate).toFixed(2) : ''}
                  </Text>
                </View>
                <View style={[styles.tdcol, { padding: 0, minWidth: 100 }]}>
                  <Text style={[styles.borderBottom, { padding: 5, }]}>
                    VOL. {volume} CBM
                  </Text>
                </View>
              </View>

              {/* -------------------------------------------------- */}

              <View style={[styles.row, { paddingTop: 12 }]} >
                <View style={[styles.tdcol, { padding: 0, textAlign: "center" }]}>
                  <View style={styles.row} >
                    <View style={[styles.tdcol, { padding: 0, }]}>
                      <Text style={{ paddingLeft: 14, paddingTop: 5, height: 20 }}>
                        {(accWeight && accWeight == 1) ? totalIataRate : ''}
                      </Text>
                    </View>
                    <View style={[styles.tdcol, { padding: 0, }]}>
                      <Text style={{ paddingLeft: 14, paddingTop: 5, height: 20, }}>
                        {(accWeight && accWeight == 2) ? totalIataRate : ''}
                      </Text>
                    </View>
                  </View>

                  <View style={styles.row} >
                    <View style={[styles.tdcol, { padding: 0, }]}>
                      <Text style={{ padding: 5, height: 20 }}>

                      </Text>
                    </View>


                    <View style={[styles.tdcol, { padding: 0, }]}>
                      <Text style={{ padding: 5, height: 20 }}>

                      </Text>
                    </View>
                  </View>
                  <View style={[styles.row, { paddingTop: 10 }]} >
                    <View style={[styles.tdcol, { padding: 0, }]}>
                      <Text style={{ padding: 5, height: 20 }}>

                      </Text>
                    </View>


                    <View style={[styles.tdcol, { padding: 0, }]}>
                      <Text style={{ padding: 5, height: 20 }}>

                      </Text>
                    </View>
                  </View>
                </View>

                <View style={[styles.tdcol, { padding: 0, paddingTop: 10, minWidth: 120 }]}>

                  {otherChargePrinting && otherChargePrinting.map((element, index) => (
                    <Text>
                      {element.charge_code}{(element.agent_airline == 1) ? 'A' : (element.agent_airline == 2) ? 'C' : 'G'}
                    </Text>
                  ))}

                </View>
              </View>

              <View style={[styles.row, { paddingTop: 10 }]} >
                <View style={[styles.tdcol, { padding: 0, textAlign: "center" }]}>
                  <View style={styles.row} >
                    <View style={[styles.tdcol, { padding: 0, }]}>
                      <Text style={{ paddingLeft: 25, paddingTop: 5, height: 20 }}>
                        {(dueAgent && dueAgent == 1 && totalOtherChargeDueAgentPrepaid) ? Number(totalOtherChargeDueAgentPrepaid).toFixed(2) : ''}
                      </Text>
                    </View>
                    <View style={[styles.tdcol, { padding: 0, }]}>
                      <Text style={{ paddingLeft: 10, paddingTop: 5, height: 20 }}>
                        {(dueAgent && dueAgent == 2 && totalOtherChargeDueAgentCollect) ? Number(totalOtherChargeDueAgentCollect).toFixed(2) : ''}
                      </Text>
                    </View>
                  </View>

                  <View style={[styles.row, { paddingTop: 10 }]} >
                    <View style={[styles.tdcol, { padding: 0, }]}>
                      <Text style={{ paddingLeft: 25, paddingTop: 5, height: 20 }}>
                        {(dueCarrier && dueCarrier == 1 && totalOtherChargeDueCarrierPrepaid) ? Number(totalOtherChargeDueCarrierPrepaid).toFixed(2) : ''}
                      </Text>
                    </View>


                    <View style={[styles.tdcol, { padding: 0, }]}>
                      <Text style={{ paddingLeft: 10, paddingTop: 5, height: 20 }}>
                        {(dueCarrier && dueCarrier == 2 && totalOtherChargeDueCarrierCollect) ? Number(totalOtherChargeDueCarrierCollect).toFixed(2) : ''}
                      </Text>
                    </View>
                  </View>

                </View>

                <View style={[styles.tdcol, { padding: 0, minWidth: 120 }]}>


                  <Text style={{ paddingTop: 40, textAlign: "center" }}>
                    {signOfShipper}
                  </Text>



                </View>
              </View>


              {/* -------------------------------------------------- */}

              <View style={[styles.row, { paddingTop: 30 }]} >
                <View style={[styles.tdcol, { padding: 0, }]}>
                  <View style={styles.row} >

                    <View style={[styles.tdcol, { padding: 0, textAlign: "center", borderTopColor: '#fff' }]}>
                      <Text style={{ paddingLeft: 25, paddingTop: 5, height: 20 }}>
                        {(totalPrepaid) ? parseFloat(totalPrepaid.toFixed(2)) + totalIataRate : ''}
                      </Text>
                    </View>
                    <View style={[styles.tdcol, { padding: 0, textAlign: "center", borderTopColor: '#fff' }]}>
                      <Text style={{ paddingLeft: 10, paddingTop: 5, height: 20 }}>
                        {(totalCollect) ? parseFloat(totalCollect.toFixed(2)) + totalIataRate : ''}
                      </Text>
                    </View>


                  </View>




                  <View style={[styles.row, { paddingTop: 5 }]}>

                    <View style={[styles.tdcol, { padding: 0, textAlign: "center", }]}>
                      <Text style={{ padding: 5 }}>

                      </Text>
                    </View>
                    <View style={[styles.tdcol, { padding: 0, textAlign: "center", }]}>
                      <Text style={{ padding: 5 }}>

                      </Text>
                    </View>


                  </View>


                </View>

                <View style={[styles.tdcol, { padding: 0, minWidth: 120 }]}>
                  <View style={[styles.row, { marginTop: 10, marginBottom: 5, textAlign: "center" }]} >

                    <View style={[styles.tdcol, { padding: 0, borderColor: '#fff' }]}>
                      <Text style={{ paddingTop: 5 }}>
                        {signDate}
                      </Text>
                    </View>
                    <View style={[styles.tdcol, { padding: 0, borderColor: '#fff' }]}>
                      <Text style={{ paddingTop: 5 }}>
                        {signPlace}
                      </Text>
                    </View>
                    <View style={[styles.tdcol, { padding: 0, borderColor: '#fff', margin: 0, minWidth: 100 }]}>
                      <Text style={{ paddingTop: 5 }}>
                        {signOfCarrier}
                      </Text>
                    </View>


                  </View>




                </View>
              </View>

              {/* -------------------------------------------------- */}


              <View style={styles.row} >
                <View style={[styles.tdcol, { padding: 0, }]}>
                  <View style={styles.row} >

                    <View style={[styles.tdcol, { padding: 0, textAlign: "center", borderTopColor: '#fff', borderBottomColor: '#fff' }]}>
                      <Text style={{ padding: 5 }}>

                      </Text>
                    </View>
                    <View style={[styles.tdcol, { padding: 0, textAlign: "center", borderTopColor: '#fff' }]}>
                      <Text style={{ paddingTop: 15 }}>

                      </Text>
                    </View>


                  </View>





                </View>

                <View style={[styles.tdcol, { padding: 0, minWidth: 120, borderBottomColor: '#fff', borderRightColor: '#fff' }]}>
                  <View style={styles.row} >
                    <View style={[styles.tdcol, { padding: 0, textAlign: "center", maxWidth: 100, borderTopColor: '#fff', borderLeftColor: '#fff' }]}>
                      <Text style={{ paddingTop: 15 }}>

                      </Text>
                    </View>
                    <View style={[styles.tdcol, { padding: 0, textAlign: "center", maxWidth: 100, borderTopColor: '#fff', borderLeftColor: '#fff' }]}>
                      <Text style={{ paddingTop: 15 }}>

                      </Text>
                    </View>
                  </View>

                </View>

              </View>


            </Page>
          </Document>
        </PDFViewer>
      </div>
    </>
  );
}

export default AirwayBillPdf;
