import InputField from "components/Fields/InputField";
import React from "react";
import DeleteIcon from "@mui/icons-material/Delete";

export default function Item({ index, data, setValues, removeItem }) {
  const getIntegerValue = (e) => {
    const value = parseInt(e.target.value);
    if (isNaN(value)) {
      return 0;
    }
    return value;
  };
  const getIntVal = (number) => {
    return Number(parseFloat(number).toFixed(3));
  };
  const onChangeTitle = (e) => {
    data.name = e.target.value;
    setValues(index, data);
  };
  const onChangeCost = (e) => {
    const value = getIntegerValue(e);
    data.profit = getIntVal((getIntVal(data.margin) * value) / 100);
    data.total = getIntVal(data.profit) + value;
    data.cost = value;
    setValues(index, data);
  };
  const onChangeMargin = (e) => {
    const value = getIntegerValue(e);
    data.profit = getIntVal((value * getIntVal(data.cost)) / 100);
    data.total = getIntVal(data.profit) + getIntVal(data.cost);
    data.margin = value;
    setValues(index, data);
  };
  const onChangeTotal = (e) => {
    const value = getIntegerValue(e);
    data.cost = value - getIntVal(data.profit);
    data.margin = getIntVal(
      (getIntVal(data.profit) / getIntVal(data.cost)) * 100
    );
    data.total = value;
    setValues(index, data);
  };
  const onChangeProfit = (e) => {
    const value = getIntegerValue(e);
    data.profit = value;
    data.cost = getIntVal(data.total) - value;
    data.margin = getIntVal(
      (getIntVal(data.profit) / getIntVal(data.cost)) * 100
    );
    setValues(index, data);
  };
  const onChangeComment = (e) => {
    data.comment = e.target.value;
    setValues(index, data);
  };
  return (
    <tr className="p-3 mt-4">
      <td style={{ minWidth: "250px" }}>
        <InputField
          id="title"
          placeholder="Item Name"
          label=""
          value={data.name}
          onChange={onChangeTitle}
        />
      </td>
      <td>
        <InputField
          id="cost"
          placeholder="Cost"
          label=""
          value={data.cost}
          onChange={onChangeCost}
        />
      </td>
      <td>
        <InputField
          id="margin"
          placeholder="Margin"
          label=""
          value={data.margin}
          onChange={onChangeMargin}
        />
      </td>
      <td>
        <InputField
          id="total"
          placeholder="Total"
          label=""
          value={data.total}
          onChange={onChangeTotal}
        />
      </td>
      <td>
        <InputField
          id="profit"
          placeholder="Profit"
          label=""
          value={data.profit}
          onChange={onChangeProfit}
        />
      </td>
      <td style={{ minWidth: "250px" }}>
        <InputField
          id="comment"
          placeholder="Comment"
          label=""
          value={data.comment}
          onChange={onChangeComment}
        />
      </td>
      <td>
        <DeleteIcon
          onClick={() => removeItem(index)}
          className="text-danger cursor-p"
        />
      </td>
    </tr>
  );
}
