import React, { Fragment, useContext, useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import Dropdown from "components/Fields/Dropdown";
import InputField from "components/Fields/InputField";
import { Col, Row } from "react-bootstrap";
import Item from "./Item";
import NoItemsFound from "./NoItemsFound";
import AxiosContext from "store/axios-context";
import { useHistory } from "react-router";
import "./Quote.css";
import { Checkbox, FormControlLabel, FormGroup } from "@mui/material";

export default function Details({
  data,
  customers,
  destinations,
  shipmentTerms,
  currencies,
}) {
  const history = useHistory();
  const axiosCtx = useContext(AxiosContext);
  const [editId, setEditId] = useState(null);
  const [formError, setFormError] = useState(null);
  const [customer, setCustomer] = useState(data.cust_id);
  const [fromDestination, setFromDestination] = useState(data.from_id);
  const [toDestination, setToDestination] = useState(data.to_id);
  const [pieces, setPieces] = useState(data.pieces);
  const [actualWeight, setActualWeight] = useState(data.act_wt);
  const [chargeableWeight, setChargeableWeight] = useState(data.charge_wt);
  const [volume, setVolume] = useState(data.volume);
  const [shipmentTerm, setShipmentTerm] = useState(data.shipment_id);
  const [currency, setCurrency] = useState(data.currency);
  const [hideTotal, setHideTotal] = useState(data.hide_total === "1");
  const [description, setDescription] = useState(data.description);
  const [items, setItems] = useState(data.items);
  const [total, setTotal] = useState({ cost: 0, margin: 0, sum: 0, profit: 0 });
  useEffect(() => {
    data.id && setEditId(data.id);
    data.customer && setCustomer(data.customer);
    data.currency && setCurrency(data.currency);
    data.from && setFromDestination(data.from);
    data.to && setToDestination(data.to);
    data.shipement && setShipmentTerm(data.shipement);
    data.pieces && setPieces(data.pieces);
    data.act_wt && setActualWeight(data.act_wt);
    data.charge_wt && setChargeableWeight(data.charge_wt);
    data.volume && setVolume(data.volume);
    data.description && setDescription(data.description);
    data.hide_total && setHideTotal(data.hide_total === "1");
    data.items && setItems(data.items);
  }, [data]);
  useEffect(() => {
    let temp = { cost: 0, margin: 0, sum: 0, profit: 0 };
    items.forEach((item) => {
      let cost = parseFloat(item.cost);
      if (!isNaN(cost)) {
        temp.cost += cost;
      }
      let sum = parseFloat(item.total);
      if (!isNaN(sum)) {
        temp.sum += sum;
      }
      let profit = parseFloat(item.profit);
      if (!isNaN(profit)) {
        temp.profit += profit;
      }
    });
    if (temp.cost > 0) {
      temp.margin = Number(((temp.profit / temp.cost) * 100).toFixed(3));
    }
    setTotal(temp);
  }, [items]);

  const addNewItem = () => {
    const tmp = [...items];
    tmp.push({
      title: "",
      cost: 0,
      margin: 0,
      total: 0,
      profit: 0,
      comment: "",
    });
    setItems(tmp);
  };
  const removeItem = (index) => {
    const tmp = [...items];
    tmp.splice(index, 1);
    setItems(tmp);
  };
  const setValues = (index, values) => {
    const tmp = [...items];
    tmp[index] = values;
    setItems(tmp);
  };

  const saveQuote = (e) => {
    e.preventDefault();
    const data = {
      cust_id: customer.id,
      from_id: fromDestination.id,
      to_id: toDestination.id,
      pieces: pieces,
      act_wt: actualWeight,
      charge_wt: chargeableWeight,
      volume: volume,
      shipment_id: shipmentTerm.id,
      currency: currency.id,
      description: description,
      hide_total: hideTotal === true,
      items: items,
    };
    let url = "store-quotation";
    if (editId) {
      data.id = editId;
      url = "update-quotation";
    }
    axiosCtx.instance
      .post(url, data)
      .then((response) => {
        history.push({
          pathname: `/quotations`,
          state: {},
        });
      })
      .catch((error) => {
        if (error.response.status === 422) {
          setFormError(error.response.data);
        } else {
          alert("Something went wrong !");
        }
      });
  };
  const saveAsNewQuote = (e) => {
    e.preventDefault();
    const data = {
      cust_id: customer.id,
      from_id: fromDestination.id,
      to_id: toDestination.id,
      pieces: pieces,
      act_wt: actualWeight,
      charge_wt: chargeableWeight,
      volume: volume,
      shipment_id: shipmentTerm.id,
      currency: currency.id,
      hide_total: hideTotal === true,
      description: description,
      items: items,
    };
    axiosCtx.instance
      .post("store-quotation", data)
      .then((response) => {
        history.push({
          pathname: `/quotations`,
          state: {},
        });
      })
      .catch((error) => {
        if (error.response.status === 422) {
          setFormError(error.response.data);
        } else {
          alert("Something went wrong !");
        }
      });
  };

  return (
    <Fragment>
      <Card className="p-4">
        <h4 className="m-0 mb-2">Create Quotation</h4>
        <Row className="justify-content-between">
          <Col md="5" className="my-3">
            <Dropdown
              id="cust_id"
              placeholder="Customer"
              label="name"
              value={customer}
              options={customers}
              onChange={(event, newValue) => setCustomer(newValue)}
              formError={
                formError && formError.errors.cust_id
                  ? formError.errors.cust_id
                  : ""
              }
            />
          </Col>
          <Col md="4" className="my-3">
            <Dropdown
              id="currency"
              placeholder="Currency"
              label="currency_label"
              value={currency}
              options={currencies}
              onChange={(event, newValue) => setCurrency(newValue)}
              formError={
                formError && formError.errors.currency
                  ? formError.errors.currency
                  : ""
              }
            />
          </Col>
        </Row>
        {customer && (
          <Row>
            <Col md="12" className="ml-3 my-1" style={{ fontWeight: 600 }}>
              {customer.address_1}
              {customer.address_1 && <br />}
              {customer.address_2}
              {customer.address_2 && <br />}
              {customer.city}
              {customer.city && <br />}
              {customer.country}
              {customer.country && <br />}
              {customer.zip_code}
              {customer.zip_code && <br />}
            </Col>
          </Row>
        )}
        <Row>
          <Col md="6" className="my-3">
            <Dropdown
              id="from_id"
              placeholder="From"
              label="code"
              label1="name"
              value={fromDestination}
              options={destinations}
              onChange={(event, newValue) => setFromDestination(newValue)}
              formError={
                formError && formError.errors.from_id
                  ? formError.errors.from_id
                  : ""
              }
            />
          </Col>
          <Col md="6" className="my-3">
            <Dropdown
              id="to_id"
              placeholder="To"
              label="code"
              label1="name"
              value={toDestination}
              options={destinations}
              onChange={(event, newValue) => setToDestination(newValue)}
              formError={
                formError && formError.errors.to_id
                  ? formError.errors.to_id
                  : ""
              }
            />
          </Col>
        </Row>
        <Row>
          <Col md="3" className="my-3 form-group">
            <InputField
              id="pieces"
              placeholder="Pieces"
              label="Pieces"
              value={pieces}
              onChange={(event) => setPieces(event.target.value)}
              formError={
                formError && formError.errors.pieces
                  ? formError.errors.pieces
                  : ""
              }
            />
          </Col>
          <Col md="3" className="my-3 form-group d-flex">
            <InputField
              id="act_wt"
              placeholder="Actual Weight"
              label="Actual Weight"
              value={actualWeight}
              onChange={(event) => setActualWeight(event.target.value)}
              formError={
                formError && formError.errors.act_wt
                  ? formError.errors.act_wt
                  : ""
              }
            />
            <button
              className="btn btn-simple flex-shrink-0 my-0"
              style={{ height: "2.8em" }}
              disabled
            >
              KG
            </button>
          </Col>
          <Col md="3" className="my-3 form-group d-flex">
            <InputField
              id="charge_wt"
              placeholder="Chargeable Weight"
              label="Chargeable Weight"
              value={chargeableWeight}
              onChange={(event) => setChargeableWeight(event.target.value)}
              formError={
                formError && formError.errors.charge_wt
                  ? formError.errors.charge_wt
                  : ""
              }
            />
            <button
              className="btn btn-simple flex-shrink-0 my-0"
              style={{ height: "2.8em" }}
              disabled
            >
              KG
            </button>
          </Col>
          <Col md="3" className="my-3 form-group">
            <InputField
              id="volume"
              placeholder="Volume"
              label="Volume"
              value={volume}
              onChange={(event) => setVolume(event.target.value)}
              formError={
                formError && formError.errors.volume
                  ? formError.errors.volume
                  : ""
              }
            />
          </Col>
          <Col md="6" className="my-3">
            <Dropdown
              id="shipment_id"
              placeholder="Shipment Term"
              label="title"
              value={shipmentTerm}
              options={shipmentTerms}
              onChange={(event, newValue) => setShipmentTerm(newValue)}
              formError={
                formError && formError.errors.shipment_id
                  ? formError.errors.shipment_id
                  : ""
              }
            />
          </Col>
          <Col md="6" className="my-3 form-group">
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={hideTotal}
                    onChange={(e) => setHideTotal(e.target.checked)}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                }
                label="Hide Total"
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md="6" className="my-3 form-group">
            <InputField
              id="description"
              placeholder="Description"
              label="Description"
              value={description}
              onChange={(event) => setDescription(event.target.value)}
              multiline={true}
              rows={4}
              formError={
                formError && formError.errors.description
                  ? formError.errors.description
                  : ""
              }
            />
          </Col>
        </Row>

        <h4 className="m-0">Items</h4>
        <div className="overflow-scroll">
          <table className="item-table">
            <thead>
              <tr>
                <th>Item Name</th>
                <th>Cost</th>
                <th>Margin</th>
                <th>Total</th>
                <th>Profit</th>
                <th>Comment</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {items.length ? (
                items.map((data, index) => (
                  <Item
                    key={index}
                    index={index}
                    data={data}
                    setValues={setValues}
                    removeItem={removeItem}
                  />
                ))
              ) : (
                <tr>
                  <td colSpan={6}>
                    <NoItemsFound />
                  </td>
                </tr>
              )}
            </tbody>
            <tfoot>
              <tr>
                <th>Total</th>
                <th>{total.cost}</th>
                <th>{total.margin}</th>
                <th>{total.sum}</th>
                <th>{total.profit}</th>
                <th>
                  <div className="text-right">
                    <button
                      type="button"
                      onClick={addNewItem}
                      className="btn btn-simple m-0"
                    >
                      Add Item
                    </button>
                  </div>
                </th>
                <th></th>
              </tr>
            </tfoot>
          </table>
        </div>
        <div className="text-right border-top mt-4">
          {editId && (
            <button onClick={saveAsNewQuote} className="btn btn-primary mt-4">
              Save As New
            </button>
          )}
          <button onClick={saveQuote} className="btn btn-primary mt-4">
            Save
          </button>
        </div>
      </Card>
    </Fragment>
  );
}
